import React from 'react'

const UserContext = React.createContext()
export const MetaDataContext = React.createContext()
export const SelectedPropertyContext = React.createContext()
export const GlobalMethodContext = React.createContext()
export const SelectedMenuItemContext = React.createContext()
export const ApplicationStatusContext = React.createContext()
export const DepartmentContext = React.createContext()
export default UserContext
