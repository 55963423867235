import React, { lazy, Suspense, useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import UserContext from '../Context/Context'
const GoogleEarthView = lazy(() =>
  import('../components/custom/GoogleEarth/GoogleEarthView')
)
const ViewVideo = lazy(() =>
  import('../components/custom/GoogleEarth/ViewVideo')
)

const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'))
const LoginPage = lazy(() => import('../pages/LoginPage/LoginPage'))
const PropertyPage = lazy(() => import('../pages/PropertyPage/PropertyPage'))
const PropertySoldPage = lazy(() =>
  import('../pages/PropertySoldPage/PropertySoldPage')
)
const PropertyAdvancedSearch = lazy(() =>
  import('../pages/PropertyAdvancedSearch/PropertyAdvancedSearch')
)
const ViewPropertyDetailsTab = lazy(() =>
  import('../pages/PropertyDetailsTab/ViewPropertyDetailsTab')
)
const EditPropertyDetailsTab = lazy(() =>
  import('../pages/PropertyDetailsTab/PropertyDetailsTab')
)
const HistoryPage = lazy(() => import('../pages/HistoryPage/HistoryPage'))
const TasksPage = lazy(() => import('../pages/TasksPage/TasksPage'))
const PropertySurveyInfo = lazy(() =>
  import('../pages/PropertySurveyInfo/PropertySurveyInfo')
)
const MapPage = lazy(() => import('../pages/MapPage/MapPage'))
const ReportsPage = lazy(() => import('../pages/ReportsPage/ReportsPage'))
const PropertyFieldReport = lazy(() =>
  import('../pages/PropertyFieldReport/PropertyFieldReport')
)
const PrivateRoute = ({ component, ...options }) => {
  const finalComponent = localStorage.userToken ? component : LoginPage
  return <Route {...options} component={finalComponent} />
}

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <PrivateRoute exact path="/property" component={PropertyPage} />
        <PrivateRoute
          exact
          path="/property-sold"
          component={PropertySoldPage}
        />
        <PrivateRoute
          exact
          path="/advancedsearch"
          component={PropertyAdvancedSearch}
        />
        <PrivateRoute
          exact
          path="/propertydetail/:id"
          component={ViewPropertyDetailsTab}
        />
        <PrivateRoute
          exact
          path="/editpropertydetail"
          component={EditPropertyDetailsTab}
        />
        <PrivateRoute exact path="/propertyhistory" component={HistoryPage} />
        <PrivateRoute
          exact
          path="/propertysurveyinfo"
          component={PropertySurveyInfo}
        />
        <PrivateRoute
          exact
          path="/propertyfieldreport"
          component={PropertyFieldReport}
        />

        <PrivateRoute
          exact
          path="/googleearthview/:id"
          component={GoogleEarthView}
        />
        <PrivateRoute exact path="/ViewVideo/:id" component={ViewVideo} />
        <PrivateRoute exact path="/tasks" component={TasksPage} />
        <PrivateRoute exact path="/map" component={MapPage} />
        <PrivateRoute exact path="/" component={ReportsPage} />
        <PrivateRoute path="/404" component={PageNotFound} />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  )
}

export default Routes
