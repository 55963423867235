const APIEndPoints = {
  GetPropertyDocument: {
    url: 'api/v1/property-document/',
    method: 'get',
  },
  PostPropertyDocument: {
    url: 'api/v1/property-document/',
    method: 'post',
  },
  DeletePropertyDocument: {
    url: 'api/v1/property-document/',
    method: 'delete',
  },
  Login: {
    url: 'api/v1/login/',
    method: 'post',
  },
  GetProfiles: {
    url: 'api/v1/profiles/',
    method: 'get',
  },
  GetProperties: {
    url: 'api/v1/properties/',
    method: 'get',
  },
  GetDeletedProperties: {
    url: 'api/v1/properties/list-deleted-properties/',
    method: 'get',
  },
  GetCities: {
    url: 'api/v1/cities/',
    method: 'get',
  },
  GetCountries: {
    url: 'api/v1/countries/',
    method: 'get',
  },
  GetDistricts: {
    url: 'api/v1/districts/',
    method: 'get',
  },
  GetStates: {
    url: 'api/v1/states/',
    method: 'get',
  },
  PostProperties: {
    url: 'api/v1/properties/',
    method: 'post',
  },
  DeleteProperties: {
    url: 'api/v1/properties/',
    method: 'delete',
  },
  GetNotes: {
    url: 'api/v1/property-notes/',
    method: 'get',
  },
  PostNotes: {
    url: 'api/v1/property-notes/',
    method: 'post',
  },
  DeleteNotes: {
    url: 'api/v1/property-notes/',
    method: 'delete',
  },
  GetTasks: {
    url: 'api/v1/property-tasks/',
    method: 'get',
  },
  PostTasks: {
    url: 'api/v1/property-tasks/',
    method: 'post',
  },
  DeleteTasks: {
    url: 'api/v1/property-tasks/',
    method: 'delete',
  },
  GetHistory: {
    url: 'api/v1/property-history/',
    method: 'get',
  },
  GetPhotos: {
    url: 'api/v1/property-photo/',
    method: 'get',
  },
  PostPhotos: {
    url: 'api/v1/property-photo/',
    method: 'post',
  },
  DeletePhotos: {
    url: 'api/v1/property-photo/',
    method: 'delete',
  },
  GetMaps: {
    url: 'api/v1/property-maps/',
    method: 'get',
  },
  GetOwner: {
    url: 'api/v1/property-details/',
    method: 'get',
  },
  PostOwner: {
    url: 'api/v1/property-details/',
    method: 'post',
  },
  DeleteOwner: {
    url: 'api/v1/property-details/',
    method: 'delete',
  },
  GetSurvay: {
    url: 'api/v1/property-survey/',
    method: 'get',
  },
  PostSurvay: {
    url: 'api/v1/property-survey/',
    method: 'post',
  },
  DeleteSurvay: {
    url: 'api/v1/property-survey/',
    method: 'delete',
  },
  GetSurvayInfo: {
    url: 'api/v1/survey-info/',
    method: 'get',
  },
  PostSurvayInfo: {
    url: 'api/v1/survey-info/',
    method: 'post',
  },
  DeleteSurvayInfo: {
    url: 'api/v1/survey-info/',
    method: 'delete',
  },
  GetFieldReport: {
    url: 'api/v1/property-feature/',
    method: 'get',
  },
  PostFieldReport: {
    url: 'api/v1/field-report/',
    method: 'post',
  },
  DeleteFieldReport: {
    url: 'api/v1/field-report/',
    method: 'delete',
  },
  GetFieldsReport: {
    url: 'api/v1/field-report/',
    method: 'get',
  },
  PatchFieldsReport: {
    url: 'api/v1/field-report/',
    method: 'patch',
  },
  GetPropertyFeature: {
    url: 'api/v1/property-feature/',
    method: 'get',
  },
  PostPropertyFeature: {
    url: 'api/v1/property-feature/',
    method: 'post',
  },
  DeletePropertyFeature: {
    url: 'api/v1/property-feature/',
    method: 'delete',
  },
  GetNeighbour: {
    url: 'api/v1/property-neighbour/',
    method: 'get',
  },
  PostNeighbour: {
    url: 'api/v1/property-neighbour/',
    method: 'post',
  },
  DeleteNeighbour: {
    url: 'api/v1/property-neighbour/',
    method: 'delete',
  },
  GetPropertyDashboard: {
    url: 'api/v1/property-card-dashboard/',
    method: 'get',
  },
  GetPropertyAdvancedSearch: {
    url: 'api/v1/property-maps/',
    method: 'get',
  },
  GetApplicationStatus: {
    url: 'api/v1/application-status/',
    method: 'get',
  },
  GetDepartments: {
    url: 'api/v1/departments/',
    method: 'get',
  },
  GetPropertyGoogleEarth: {
    url: 'api/v1/google-earth-listing/',
    method: 'get',
  },
  PostPropertyGoogleEarth: {
    url: 'api/v1/google-earth-listing/',
    method: 'post',
  },
  DeletePropertyGoogleEarth: {
    url: 'api/v1/google-earth-listing/',
    method: 'delete',
  },
}

export default APIEndPoints
