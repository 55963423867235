import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { fade, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MailIcon from '@material-ui/icons/Mail'
import PropTypes from 'prop-types'

// import CssBaseline from '@material-ui/core/CssBaseline'
import './Header.css'

// const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  //   sectionMobile: {
  //     display: 'flex',
  //     [theme.breakpoints.up('md')]: {
  //       display: 'none',
  //     },
  //   },
}))

const Header = ({ MenuIconClick, MenuOpen }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [searchValue, setSearchValue] = React.useState(null)
  const history = useHistory()
  const isMenuOpen = Boolean(anchorEl)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (event) => {
    // if (event.currentTarget.innerText === 'Sign Out') {
    // }
    setAnchorEl(null)
  }

  const signOut = () => {
    handleMenuClose()
    localStorage.clear()
    window.location.reload()
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      className="profile-menu"
      onClose={handleMenuClose}
    >
      <MenuItem onClick={signOut}>Sign Out</MenuItem>
    </Menu>
  )

  const search = (event) => {
    if (event.which === 13) {
      history.push(`/property?search=${searchValue}`)
    }
  }

  const searchChange = (event) => {
    setSearchValue(event.target.value)
  }

  return (
    <div className="headerMenu">
      <div className={classes.grow}>
        <AppBar
          position="fixed"
          className={`${clsx(classes.appBar, {
            [classes.appBarShift]: MenuOpen,
          })}header`}
        >
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={MenuIconClick}
              color="inherit"
              aria-label="open drawer"
            >
              <MenuIcon />
            </IconButton>
            <div className="title-container">
              <Typography className={classes.title} variant="h6" noWrap>
                MANDC
              </Typography>
            </div>
            <div className={`${classes.search} searchBar-container`}>
              <div className={classes.searchIcon} onClick={search}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onKeyPress={search}
                onChange={searchChange}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/* <IconButton aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="secondary">
                  <MailIcon />
                </Badge>
              </IconButton> */}
              {/* <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
      </div>
    </div>
  )
}

Header.propTypes = {
  MenuIconClick: PropTypes.func,
  MenuOpen: PropTypes.bool,
}

Header.defaultProps = {
  MenuIconClick: () => null,
  MenuOpen: false,
}

export default Header
